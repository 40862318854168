import { disableVerticals, enableVerticals } from '@/api/pmverticals';

const disableVertical = {
	id: 'disableVertical',
	selectionType: 'multiple',
	label: 'pmverticals.actions.disableVertical',
	functionality: 'UPDATE_PMVERTICALS',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmverticals.actions.disableVertical');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmverticalsid: registries[i].pmverticalsid
			});
		}

		const data = await disableVerticals(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableVertical = {
	id: 'enableVertical',
	selectionType: 'multiple',
	label: 'pmverticals.actions.enableVertical',
	functionality: 'UPDATE_PMVERTICALS',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmverticals.actions.enableVertical');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmverticalsid: registries[i].pmverticalsid
			});
		}

		const data = await enableVerticals(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableVertical, enableVertical]
};
